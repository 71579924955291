<script setup lang="ts">
import { computed, ref, defineProps } from 'vue';
import { useRouter } from 'vue-router';
import useStore from '@/store';
import ArrowRightIcon from '@/components/icons/ArrowRightIcon.vue';
import CheckIcon from '@/components/icons/CheckIcon.vue';
import CoinIcon from '@/components/icons/CoinIcon.vue';
import CountdownTimer from '@/components/CountdownTimer.vue';
import { useHapticFeedback } from '@/directives/haptic';
import { PageName } from '@/types';
import type { Task } from '@/types/tasks';
import CompressedImage from './CompressedImage.vue';

const props = defineProps<{
  task?: Task | null;
  next: boolean;
  isSeason: boolean;
}>();

const DAY = 1000 * 60 * 60 * 24;

const router = useRouter();
const store = useStore();

const nextTask = ref(props.isSeason ? store.getSeasonalTaskByDay(1) : null);
const actualTask = ref(props.isSeason ? store.getSeasonalTaskByDay(0) : null);
const season = ref(props.isSeason ? store.getSeason() : null);

const completed = computed(() => {
  if (props.task !== null) {
    return (store.completedTasks ?? []).some(
      task => task.task_id === props.task?.id,
    );
  } else if (season.value) {
    return store.completedTasks?.some(
      task => task.task_id === actualTask.value?.id,
    );
  } else {
    return false;
  }
});

const nextTaskAvailableTimestamp = computed(() => {
  if (nextTask.value) {
    return new Date(nextTask.value.start);
  }
  if (season.value) {
    return new Date(season.value.endTime);
  }
  return null;
});

const days = computed(() => {
  if (props.isSeason && season.value && actualTask.value) {
    const startSeason = new Date(season.value.startTime).getTime();
    const finishSeason = new Date(season.value.endTime).getTime();
    const actualTaskStart = new Date(actualTask.value.start).getTime();

    const daysFromStart =
      Math.ceil(Math.abs(actualTaskStart - startSeason) / DAY) + 1;
    const daysBetweenStartAndFinish = Math.ceil(
      Math.abs(finishSeason - startSeason) / DAY,
    );

    return {
      dayFromStart: daysFromStart,
      dayBetweenStartAndFinish: daysBetweenStartAndFinish,
    };
  }
  return null;
});

const coverSrc = computed(() => {
  if (props.task) {
    return `/tasks/tasks/${props.task.image}`;
  } else {
    return '/season';
  }
});

function updateActualAndNextTask() {
  setTimeout(() => {
    season.value = store.getSeason();
    nextTask.value = store.getSeasonalTaskByDay(1);
    actualTask.value = store.getSeasonalTaskByDay(0);
  }, 1000);
}

const openTask = () => {
  if (props.isSeason) {
    useHapticFeedback('soft');
    router.push({ name: PageName.Season });
  }
  if (props.task) {
    useHapticFeedback('soft');
    router.push({ name: PageName.Task, params: { id: props.task.id } });
  }
};
</script>

<template>
  <div
    class="task-in-list"
    :class="{
      'task-in-list--completed': completed,
      'task-in-list--season': isSeason,
      'task-in-list--next': next,
    }"
    @click="openTask"
  >
    <div v-if="next" class="blackout"></div>
    <div class="cover">
      <CompressedImage :src="coverSrc" alt="Quest in list cover" />
      <div v-if="next" class="next-task-label">Your next quest</div>
    </div>
    <div
      v-if="isSeason"
      class="season-info"
      :class="{
        'season-info--active': !completed,
        'season-info--inactive': completed,
      }"
    >
      {{ season?.title }} · Day {{ days?.dayFromStart }}/
      {{ days?.dayBetweenStartAndFinish }}
    </div>
    <div class="content" ref="content">
      <div class="info">
        <div class="text">
          <span
            v-if="!isSeason"
            class="title"
            :class="{
              'title--multiline': task?.multilineTitle,
            }"
          >
            {{ task ? task.title : '' }}
            {{ task?.multilineTitle ? '   ' : '' }}
          </span>
          <div v-if="isSeason">
            <br />
            <span class="title"> Daily Seasonal Tasks </span>
            <br />
            <CountdownTimer
              v-if="props.isSeason && nextTaskAvailableTimestamp"
              class="season-timer"
              :timestamp="nextTaskAvailableTimestamp"
              v-bind="
                completed ? { textBefore: 'wait' } : { textAfter: 'left' }
              "
              @completed="updateActualAndNextTask"
            />
          </div>
          <br v-if="task && !task.multilineTitle" />
          <div class="reward" v-if="task">
            <CoinIcon class="coin" />
            <span v-if="task">+{{ task.reward }}</span>
          </div>
        </div>
      </div>
      <div v-if="!next && !isSeason" class="status">
        <CheckIcon v-if="completed" />
        <ArrowRightIcon v-else />
      </div>
      <div
        v-if="isSeason"
        class="status"
        :class="{
          'status--active': !completed,
          'status--inactive': completed,
        }"
      >
        <ArrowRightIcon />
      </div>
    </div>
    <button v-if="next" class="start-button">Start quest now</button>
    <div v-if="next" class="border"></div>
  </div>
</template>

<style lang="scss" scoped>
$border-radius: 4vw;

.task-in-list {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: $border-radius;
  background: #1d1c1b;
  overflow: hidden;
  cursor: pointer;

  &--next {
    background: #31302e;
    background-image: url(../assets/pattern.svg);
  }
}

.blackout {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
}

.cover {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 22vw;

  .task-in-list--next & {
    height: 28vw;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .next-task-label {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 6vw;
    padding: 1.5vw 4vw;
    font-size: 4vw;
    font-weight: 500;
    background: #31302e;
    background-image: url(../assets/pattern.svg);
  }
}

.content {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 3.5vw;

  .task-in-list--completed & {
    background: rgba(black, 25%);
  }
}

.some-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vw;
  background: rgba(black, 25%);
  font-size: 3.5vw;

  svg {
    position: absolute;
  }

  div {
    position: relative;
    font-size: 4vw;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    padding: 15vw;
    margin-bottom: 4vw;
  }
}

.info {
  display: flex;
  align-items: center;
  padding: 2.1vw 0 2.1vw 4vw;

  .task-in-list--next & {
    padding: 3vw 0 3vw 4vw;
  }

  .task-in-list--completed & {
    opacity: 0.2;
  }
  .task-in-list--season & {
    opacity: 1;
  }
}

.title {
  white-space: nowrap;

  &--multiline {
    white-space: pre-wrap;
  }

  .task-in-list--next & {
    white-space: pre-wrap;
    line-height: 140%;
    font-size: 5.5vw;
  }
}

.reward {
  vertical-align: top;
  display: inline-flex;
  align-items: center;
  gap: 1vw;

  .task-in-list--next & {
    font-size: 4.5vw;
  }
}

.coin {
  width: 4vw;
  height: 4vw;
}

.status {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8vw;
  height: 8vw;
  margin: 3.5vw;
  background: rgba(black, 25%);
  border-radius: 50%;
  &--active {
    background: #e3232c;
    box-shadow: 0px 8px 32px 0px #e3232c80;
  }
  &--inactive {
    background: #3f3a3a;
  }
  svg {
    height: 3.5vw;
    width: auto;
    max-width: 4vw;
    fill: white;
  }
}

.start-button {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1vw 4vw 4vw 4vw;
  padding: 4vw 0;
  z-index: 1000;

  background: #e3232c;
  box-shadow: 0 1vw 8vw rgba(227, 35, 44, 0.5);
  border-radius: 100vw;

  color: white;
  text-shadow: 0 0 7vw white, 0 0 7vw rgba(255, 255, 255, 0.5);
  font-size: 4vw;
  font-weight: 800;
  border: 1px solid transparent;
}

.border {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: $border-radius;
  border: 0.4vw solid rgba(white, 0.3);
  pointer-events: none;
}
.season-info {
  font-size: 11.2px;
  font-weight: 500;
  border-radius: $border-radius;
  border: 2px solid #242121;
  width: 37vw;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  position: absolute;
  top: 45%;
  left: 3%;
  &--active {
    background: #e3232c;
  }
  &--inactive {
    background: #3f3a3a;
  }
}
.season-timer {
  display: inline-block;
  width: auto;
  border-radius: 1vw;
  font-weight: 700;
  text-align: left;
  background: black;
  padding: 0 1vw;
}
</style>
