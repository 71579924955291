<template>
  <canvas ref="canvas" width="200" height="200"></canvas>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { Rive } from '@rive-app/canvas';

const canvas = ref<HTMLCanvasElement | null>(null);
let rive: Rive | null = null;

onMounted(() => {
  const canvasElement = canvas.value;
  if (canvasElement) {
    rive = new Rive({
      src: '/animations/spinner.riv',
      canvas: canvasElement,
      onLoad: () => {
        if (!rive) return;
        rive.animationNames.forEach(name => {
          rive?.play(name, true);
        });
      },
      autoplay: true,
    });
  }
});
</script>
