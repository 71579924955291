<script setup lang="ts">
import { computed, defineProps } from 'vue';
import useStore from '@/store';
import NotDoneIcon from '@/components/icons/NotDoneIcon.vue';
import DoneIcon from '@/components/icons/DoneIcon.vue';
import { getNow } from '@/utils/timeFunctions';
import type { Season } from '@/types/season';
import type { Task } from '@/types/tasks';

const props = defineProps<{
  season?: Season | null;
  actualTask?: Task | null;
  nextTask?: Task | null;
}>();

enum Status {
  Done = 'Done',
  NotDone = 'NotDone',
  ActualDone = 'ActualDone',
  ActualNotDone = 'ActualNotDone',
  Next = 'Next',
  Future = 'Future',
}

const store = useStore();

const seasonSortTasks = computed(() => {
  if (props.season) {
    return store
      .getUniqueSeasonTasks(props.season.id)
      .sort((task1, task2) => task1.start.localeCompare(task2.start));
  }
  return [];
});

const seasonTasks = computed(() => {
  if (props.season) {
    let now = getNow();

    const getStatus = (task: Task) => {
      const taskDeadline = new Date(task.deadline);
      const isCompleted = store.completedTasks?.some(
        comTask => comTask.task_id === task.id,
      );
      if (taskDeadline <= now) {
        return isCompleted ? Status.Done : Status.NotDone;
      }

      if (task.id === props.actualTask?.id) {
        return isCompleted ? Status.ActualDone : Status.ActualNotDone;
      }

      if (task.id === props.nextTask?.id) {
        return Status.Next;
      }

      return Status.Future;
    };

    return seasonSortTasks.value.map(task => ({
      id: task.id,
      status: getStatus(task),
    }));
  }
  return [];
});

const completedTasks = computed(() => {
  return seasonTasks.value.filter(
    task => task.status === Status.Done || task.status === Status.ActualDone,
  ).length;
});
</script>

<template>
  <div class="tasks-view">
    <div class="tasks-view__description">
      <span class="tasks-view__description--left-text"> Your Progress </span>
      <span>{{ `${completedTasks}/${seasonTasks?.length}` }}</span>
    </div>
    <div class="tasks-view__circles">
      <div class="tasks-view__padding"></div>
      <div
        v-for="(task, index) in seasonTasks"
        :key="index"
        :class="[
          'tasks-view__circles-day',
          {
            'day--done': task.status === Status.Done,
            'day--not-done': task.status === Status.NotDone,
            'day--actual-done': task.status === Status.ActualDone,
            'day--actual-not-done': task.status === Status.ActualNotDone,
            'day--next': task.status === Status.Next,
            'day--future': task.status === Status.Future,
          },
        ]"
      >
        <div class="task-circle">
          <DoneIcon
            v-if="
              task.status === Status.Done || task.status === Status.ActualDone
            "
          />
          <NotDoneIcon v-if="task.status === Status.NotDone" />
        </div>
        <span>{{ `day ${index + 1}` }}</span>
      </div>
      <div class="tasks-view__padding"></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.tasks-view {
  position: relative;
  width: 95%;
  height: 15vh;
  background: transparent;
  border: 1px solid rgba(white, 0.06);
  border-radius: 2vh;
  padding-top: 1vh;

  &__description {
    display: flex;
    justify-content: space-between;
    padding-left: 1vh;
    padding-right: 1vh;
    font-size: 14px;
    font-weight: 500;
    line-height: 20.3px;
    letter-spacing: 0.02em;
    text-align: left;
    color: rgba(255, 255, 255, 1);

    &--left-text {
      color: rgba(139, 139, 139, 1);
    }
  }

  &__padding {
    width: 1vh;
  }

  &__circles {
    margin-top: 1vh;
    display: flex;
    overflow-x: auto;
    gap: 2vw;

    .day--done {
      .task-circle {
        background: #242121;
      }

      span {
        color: #71716d;
      }
    }

    .day--not-done {
      .task-circle {
        color: #8b8b8b;
      }

      span {
        color: #71716d;
      }
    }

    .day--actual-done {
      .task-circle {
        border: 1px solid #e3232c;
        background: #242121;
      }
    }

    .day--actual-not-done {
      .task-circle {
        border: 1px solid #e3232c;
        background: #e3232c33;
      }
    }

    .day--next {
      .task-circle {
        background: #e3232c33;
        border: 1px solid #ffffff0a;
      }

      span {
        color: #71716d;
      }
    }

    .day--future {
      span {
        color: #71716d;
      }
    }
  }

  &__circles::-webkit-scrollbar {
    display: none;
  }

  &__circles-day {
    font-size: 11.77px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: center;
  }
}

.task-circle {
  margin: 0 0.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.84px solid rgba(white, 0.06);
  background: transparent;
  height: 6vh;
  width: 6vh;
  border-radius: 50%;
  flex-shrink: 0;
}
</style>
