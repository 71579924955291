<template>
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.76172 0.455078C10.0752 0.455078 12.7617 3.14158 12.7617 6.45508C12.7617 9.76858 10.0752 12.4551 6.76172 12.4551C3.44822 12.4551 0.761719 9.76858 0.761719 6.45508C0.761719 3.14158 3.44822 0.455078 6.76172 0.455078ZM6.76172 3.02651C6.33567 3.02651 5.99029 3.37189 5.99029 3.79794V6.45508C5.99029 6.5554 6.00944 6.65126 6.04441 6.73945C6.04878 6.7505 6.05342 6.76147 6.05833 6.77235L6.08068 6.81773L6.09071 6.83606L6.10163 6.85453C6.11321 6.87362 6.1256 6.89218 6.13853 6.90995C6.14691 6.92139 6.15563 6.93266 6.16501 6.94403C6.17562 6.95697 6.18666 6.96956 6.1978 6.98149L6.20692 6.99109L7.93052 8.71485L8.00952 8.78389C8.31189 9.01402 8.74533 8.991 9.02149 8.71485L9.09053 8.63585C9.32066 8.33348 9.29764 7.90004 9.02149 7.62388L7.53229 6.13451L7.53315 3.79794C7.53315 3.37189 7.18777 3.02651 6.76172 3.02651Z"
      fill="white"
    />
  </svg>
</template>
