import { PageName } from './types';
import { SocialPlatform } from './types/tasks';

export const AUTHORIZED_PAGES = [
  PageName.Tap,
  PageName.Earn,
  PageName.Referrals,
  PageName.World,
  PageName.Location,
  PageName.Task,
  PageName.TaskSurvey,
  PageName.Season,
  PageName.SeasonReward,
];

export const MIN_LOADING_DELAY = 2500;

export const TAP_INTERVAL = 4 * 3600; // 4 hours
export const CHECK_IN_INTERVAL = 24 * 3600; // 1 day

export const CHECK_IN_REWARDS = [
  50, 50, 50, 50, 50, 50, 1000,
  // eslint-disable-line prettier/prettier
  50, 50, 50, 50, 50, 50, 2000,
  // eslint-disable-line prettier/prettier
  50, 50, 50, 50, 50, 50, 5000,
  // eslint-disable-line prettier/prettier
  50, 50, 50, 50, 50, 50, 10000,
];

export const REFERRAL_TIERS = [0, 1, 2, 5, 10, 50, 100];
export const REWARD_PER_REF = 200;

export const REF_CODE_LENGTH = 13;
export const REF_CODE_REGEXP = /^[A-Za-z0-9]+$/;

export const INVITE_TEXT = `
Join me in SORATOPIA! Become an ultra-based Samurai or a waifu fanatic and earn rewards!

💸 Refer friends and earn 10% of their points
🔥 Perks for early newcomers in the huge airdrop 

Are you ready?`;

export const REFERRALS_PER_PAGE = 10;

export const INVITE_COST = 50;

export const INVITE_BUY_OPTIONS = [2, 4, 8];

export const SOCIAL_PLATFORM_PREFIXES = {
  [SocialPlatform.X]: '@',
  [SocialPlatform.Telegram]: '@',
  [SocialPlatform.Discord]: '',
};

export const SOCIAL_PLATFORM_USERNAME_REGEXP = {
  [SocialPlatform.X]: /^[a-zA-Z0-9_]{4,15}$/,
  [SocialPlatform.Telegram]: /^[a-zA-Z0-9_]{5,32}$/,
  [SocialPlatform.Discord]: /^[a-zA-Z0-9_.]{2,32}$/,
};
