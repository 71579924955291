<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.663995 0.663995C1.01547 0.312523 1.58532 0.312523 1.93679 0.663995L7.00039 5.7276L12.064 0.663995C12.4155 0.312523 12.9853 0.312523 13.3368 0.663995C13.6883 1.01547 13.6883 1.58531 13.3368 1.93679L8.27318 7.00039L13.3368 12.064C13.6883 12.4155 13.6883 12.9853 13.3368 13.3368C12.9853 13.6883 12.4155 13.6883 12.064 13.3368L7.00039 8.27318L1.93679 13.3368C1.58532 13.6883 1.01547 13.6883 0.663995 13.3368C0.312523 12.9853 0.312523 12.4155 0.663995 12.064L5.7276 7.00039L0.663995 1.93679C0.312523 1.58531 0.312523 1.01547 0.663995 0.663995Z"
      fill="white"
    />
  </svg>
</template>
