export function getNow() {
  let now = new Date();
  const lastCheckInDate = new Date(
    Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
    ),
  );
  now = new Date(lastCheckInDate);
  now.setUTCDate(now.getUTCDate());
  return now;
}
