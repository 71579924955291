import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import { PageName } from '@/types';

import LoginView from '@/views/LoginView.vue';
import TapView from '@/views/TapView.vue';
import ReferralsView from '@/views/ReferralsView.vue';
import EarnView from '@/views/EarnView.vue';
import WorldView from '@/views/WorldView.vue';
import LocationView from '@/views/LocationView.vue';
import TaskView from '@/views/TaskView.vue';
import SurveyView from '@/views/SurveyView.vue';
import UnsupportedPlatformView from '@/views/UnsupportedPlatformView.vue';
import SeasonView from '@/views/SeasonView.vue';
import SeasonRewardView from '@/views/SeasonRewardView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: PageName.Root,
    redirect: { name: PageName.Login },
  },
  {
    path: '/login',
    name: PageName.Login,
    component: LoginView,
  },
  {
    path: '/tap',
    name: PageName.Tap,
    component: TapView,
  },
  {
    path: '/earn',
    name: PageName.Earn,
    component: EarnView,
  },
  {
    path: '/referrals',
    name: PageName.Referrals,
    component: ReferralsView,
  },
  {
    path: '/tasks',
    name: PageName.World,
    component: WorldView,
  },
  {
    path: '/tasks/:locationId',
    name: PageName.Location,
    component: LocationView,
  },
  {
    path: '/task/:id',
    name: PageName.Task,
    component: TaskView,
  },
  {
    path: '/task/:id/survey/:step',
    name: PageName.TaskSurvey,
    component: SurveyView,
  },
  {
    path: '/unsupported',
    name: PageName.UnsupportedPlatform,
    component: UnsupportedPlatformView,
  },
  {
    path: '/season',
    name: PageName.Season,
    component: SeasonView,
  },
  {
    path: '/season/reward',
    name: PageName.SeasonReward,
    component: SeasonRewardView,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
