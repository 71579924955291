import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { plugin } from '@soramitsu-ui/ui';
import { setInitDataFromDotEnv } from './utils';
// Import global styles + App.vue with global styles: the order is important
import '@soramitsu-ui/ui/dist/style.css';
import '@/assets/styles';
import App from './App.vue';

import router from './router';
import { haptic } from './directives/haptic';

if (process.env.VUE_APP_INIT_DATA) {
  setInitDataFromDotEnv(process.env.VUE_APP_INIT_DATA);
}

const app = createApp(App);

app.directive('haptic', haptic);

app.use(plugin()).use(createPinia()).use(router).mount('#app');
