<template>
  <svg
    width="15"
    height="11"
    viewBox="0 0 15 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.9591 1.16496C14.3496 1.55549 14.3496 2.18865 13.9591 2.57918L5.95906 10.5792C5.56854 10.9697 4.93537 10.9697 4.54485 10.5792L1.04485 7.07918C0.654322 6.68865 0.654322 6.05549 1.04485 5.66496C1.43537 5.27444 2.06854 5.27444 2.45906 5.66496L5.25195 8.45786L12.5448 1.16496C12.9354 0.774439 13.5685 0.774439 13.9591 1.16496Z"
      fill="white"
    />
  </svg>
</template>
