<template>
  <svg
    width="58"
    height="53"
    viewBox="0 0 58 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.7663 5.04319C24.981 -0.524814 33.0177 -0.524812 36.2324 5.04319L56.1856 39.6032C59.4003 45.1712 55.3819 52.1312 48.9525 52.1312H9.0461C2.61673 52.1312 -1.40163 45.1712 1.81306 39.6032L21.7663 5.04319ZM31.7429 7.63519C30.5235 5.52319 27.4751 5.52319 26.2558 7.63519L6.30253 42.1952C5.08317 44.3072 6.60737 46.9472 9.0461 46.9472H48.9525C51.3913 46.9472 52.9155 44.3072 51.6961 42.1952L31.7429 7.63519Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28.9994 14.9793C30.4309 14.9793 31.5914 16.0569 31.5914 17.3861L31.5912 31.2924C31.5912 32.6217 30.4307 33.6993 28.9992 33.6993C27.5677 33.6993 26.4072 32.6217 26.4072 31.2924L26.4074 17.3861C26.4074 16.0569 27.5678 14.9793 28.9994 14.9793Z"
      fill="white"
    />
    <path
      d="M31.8794 39.4593C31.8794 41.0499 30.5899 42.3393 28.9994 42.3393C27.4088 42.3393 26.1194 41.0499 26.1194 39.4593C26.1194 37.8687 27.4088 36.5793 28.9994 36.5793C30.5899 36.5793 31.8794 37.8687 31.8794 39.4593Z"
      fill="white"
    />
  </svg>
</template>
