<template>
  <div class="loading-background">
    <canvas ref="canvas" width="600" height="1300"></canvas>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, defineProps } from 'vue';
import { Rive } from '@rive-app/canvas';

const props = defineProps<{
  loading: boolean;
}>();

const canvas = ref<HTMLCanvasElement | null>(null);
const src = '/animations/loading-new.riv';
const stateMachines = 'State Machine 1';
let rive: Rive | null = null;

onMounted(() => {
  const canvasElement = canvas.value;
  if (canvasElement) {
    rive = new Rive({
      src,
      canvas: canvasElement,
      stateMachines,
      autoplay: true,
      onLoad: () => {
        if (!rive) return;
        rive.resizeDrawingSurfaceToCanvas();
        const inputEnter = rive.stateMachineInputs(stateMachines)[1];
        const inputExit = rive.stateMachineInputs(stateMachines)[0];
        inputEnter.value = 1;
        inputExit.value = 1;
      },
    });

    setInterval(() => {
      if (
        !rive ||
        !rive.stateMachineInputs ||
        !rive.stateMachineInputs(stateMachines)
      )
        return;
      const inputEnter = rive.stateMachineInputs(stateMachines)[1];
      const newValue = props.loading ? 1 : 0;
      if (inputEnter && inputEnter.value !== newValue) {
        inputEnter.value = newValue;
      }
    }, 100);
  }
});
</script>

<style lang="scss" scoped>
.loading-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  canvas {
    width: 100%;
  }
}
</style>
