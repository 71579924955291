<script setup lang="ts">
import { computed, ref } from 'vue';
import { SModal, SModalCard } from '@soramitsu-ui/ui';
import { useRouter } from 'vue-router';
import useStore from '@/store';
import CountdownTimer from '@/components/CountdownTimer.vue';
import WarningTriangleIcon from '@/components/icons/WarningTriangleIcon.vue';
import SeasonDays from '@/components/SeasonDays.vue';
import { useHapticFeedback } from '@/directives/haptic';
import { PageName } from '@/types';
import CompressedImage from './CompressedImage.vue';

const store = useStore();
const router = useRouter();

const modalSettings = {
  closeOnOverlayClick: true,
  closeOnEsc: true,
  focusTrap: true,
  eager: false,
};
const showModal = ref(true);

const season = ref(store.getSeason());
const nextTask = ref(store.getSeasonalTaskByDay(1));
const actualTask = ref(store.getSeasonalTaskByDay(0));
const timeTimer = ref<number | null>(null);

const nextTaskAvailableTimestamp = computed(() => {
  if (nextTask.value) {
    return new Date(nextTask.value.start);
  }
  if (season.value) {
    return new Date(season.value.endTime);
  }
  return null;
});

const actionButtonText = computed(() => {
  return 'Let’s start';
});

function setTimeTimer(value: number) {
  timeTimer.value = value;
}

const isRush = computed(() => {
  return timeTimer.value === null ? false : timeTimer.value < 4 * 60 * 60;
});

function performAction() {
  useHapticFeedback('soft');
  router.push({ name: PageName.Season });
}

const updateData = () => {
  setTimeout(() => {
    season.value = store.getSeason();
    nextTask.value = store.getSeasonalTaskByDay(1);
    actualTask.value = store.getSeasonalTaskByDay(0);
  }, 1000);
};
</script>

<template>
  <SModal v-model:show="showModal" v-bind="modalSettings">
    <SModalCard class="modal season-modal">
      <div class="season-modal-view">
        <div class="season-modal-view__cover">
          <CompressedImage src="season" alt="Quest cover" />
          <div class="season-modal-view__cover-blackout"></div>
        </div>
        <WarningTriangleIcon
          v-if="isRush"
          class="season-modal-view__warning-icon"
        />
        <h1 class="season-modal-view__title">
          Complete your<br />Seasonal Tasks
        </h1>
        <SeasonDays
          class="season-modal-view__tasks-view"
          :next-task="nextTask"
          :actual-task="actualTask"
          :season="season"
        />
        <div
          :class="[
            'season-modal-view__timer-view',
            {
              'season-modal-view__timer-view--rush': isRush,
            },
          ]"
        >
          <WarningTriangleIcon class="warning-icon" v-if="isRush" />
          <CountdownTimer
            v-if="nextTaskAvailableTimestamp"
            :timestamp="nextTaskAvailableTimestamp"
            textAfter="left"
            @completed="updateData"
            @time-update="setTimeTimer"
          />
        </div>
        <div class="season-modal-view__action-button--wrapper">
          <button
            class="season-modal-view__action-button"
            @click="performAction"
          >
            {{ actionButtonText }}
          </button>
        </div>
      </div>
    </SModalCard>
  </SModal>
</template>

<style scoped lang="scss">
.season-modal-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 46vw;
  &__cover {
    position: relative;
    width: 100%;
    height: 22vh;

    img {
      width: 100%;
      height: 100%;
      padding-bottom: 2px;
      object-fit: cover;
      vertical-align: bottom;
    }
  }
  &__warning-icon {
    position: fixed;
    top: 23%;
  }
  &__title {
    position: relative;
    width: 92vw;
    margin-top: -10vh;
    line-height: 100%;
    text-align: center;
    white-space: pre-wrap;

    font-size: 24px;
    font-weight: 700;
  }

  &__cover-blackout {
    position: absolute;
    height: 30vh;
    width: 100vw;
    left: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(20, 20, 20, 0) 0%, #0e0e0e 100%);
  }

  &__tasks-view {
    position: relative;
    margin-top: 7vh;
  }

  &__timer-view {
    position: relative;
    margin-top: 3vh;
    display: flex;
    width: 60vw;
    justify-content: center;
    align-items: center;
    border-radius: 12px 12px 0 0;
    border: 1px solid black;
    background: #3d353566;
    padding: 3vw 0;
    font-size: 17px;
    font-weight: 400;
    text-align: center;
    gap: 2vw;
  }

  &__timer-view--rush {
    background: rgba(227, 35, 44, 0.2);

    .warning-icon {
      position: relative;
      top: 0;
      width: 17px;
      height: 15px;
    }
  }
  &__action-button {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3vw 0;
    z-index: 1000;
    width: 100%;
    background: #e3232c;
    box-shadow: 0 1vw 5vw rgba(227, 35, 44, 0.5);
    border-radius: 100vw;

    color: white;
    font-size: 4vw;
    font-weight: 700;
    border: 1px solid transparent;

    &--wrapper {
      position: relative;
      top: -1vh;
      display: flex;
      justify-content: center;
      width: 75vw;
    }
  }
}
</style>
<style lang="scss">
.s-modal__root .season-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 60vh;
  border-radius: 4vw 4vw 0 0;
  .s-modal-card__header {
    align-items: flex-start;
    background: #31302e;
    height: 5vh;

    .s-button {
      background-color: rgba(white, 0.2);
      z-index: 999;
      svg {
        fill: white;
      }
    }
  }
  .s-modal-card__content {
    padding: 0;
    position: relative;
    top: -5vh;
    background: #1d1c1b;
    border-radius: 4vw 4vw 0 0;
  }
  @media (max-height: 666px) {
    .season-modal-view {
      &__tasks-view {
        margin-top: 4vh;
      }
    }
  }
}
</style>
