<template>
  <form class="code-input" @submit.prevent>
    <div class="content">
      <input
        ref="input"
        v-for="(_, index) in REF_CODE_LENGTH"
        :key="index"
        class="input"
        :class="{ 'input--empty': !code[index] }"
        v-model="code[index]"
        type="text"
        maxlength="13"
        pattern="[A-Z0-9]"
        @input="onInput(index, $event)"
        @focus="onFocus(index)"
        @keyup="onKeyup($event, index)"
      />
    </div>
  </form>
</template>

<script setup lang="ts">
import { ref, watch, nextTick, defineEmits } from 'vue';
import { REF_CODE_LENGTH, REF_CODE_REGEXP } from '@/const';
import { useHapticFeedback } from '@/directives/haptic';

const emit = defineEmits(['input']);

const code = ref(Array(REF_CODE_LENGTH).fill('') as string[]);
const inputRefs = ref<HTMLInputElement[]>([]);

const onInput = (index: number, event: Event) => {
  const inputEvent = event as InputEvent;
  const value = inputEvent.data || '';
  if (value.length === REF_CODE_LENGTH && REF_CODE_REGEXP.test(value)) {
    value
      .toUpperCase()
      .split('')
      .forEach((char, charIndex) => {
        code.value[charIndex] = value[charIndex];
      });
  } else {
    const char = value.split('')[0].toUpperCase();
    code.value[index] = REF_CODE_REGEXP.test(char) ? char : '';
  }
  if (code.value[index].length === 1) {
    useHapticFeedback('soft');
    focusNextInput(index);
  }
};

const onFocus = (index: number) => {
  code.value[index] = '';
};

const onKeyup = (event: KeyboardEvent, index: number) => {
  if (event.key === 'Backspace' && index > 0) {
    useHapticFeedback('soft');
    focusPreviousInput(index);
  }
};

const focusNextInput = (index: number) => {
  if (index < REF_CODE_LENGTH - 1) {
    nextTick(() => {
      const nextInput = inputRefs.value[index + 1];
      nextInput?.focus();
    });
  }
};

const focusPreviousInput = (index: number) => {
  if (index > 0) {
    nextTick(() => {
      const prevInput = inputRefs.value[index - 1];
      prevInput?.focus();
    });
  }
};

watch(
  code,
  () => {
    const codeStr = code.value.join('');
    if (codeStr.length === REF_CODE_LENGTH) {
      emit('input', codeStr);
    }
  },
  { deep: true },
);
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap');

.code-input {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.content {
  position: relative;
  width: 92vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.input {
  width: 6vw;
  height: 12vw;
  padding-bottom: 0.5vw;
  text-align: center;
  border: none;
  border-radius: 1vw;
  background: rgba(143, 143, 143, 0.15);
  color: white;
  caret-color: #db3b3c;
  outline: none;
  transition: background 0.5s ease;

  font-family: 'Oswald', sans-serif;
  font-optical-sizing: auto;
  font-size: 8.5vw;
  font-weight: 700;
  font-style: normal;

  &:focus {
    background: rgba(#db3b3c, 0.25);
  }

  &--empty {
    font-size: 6vw;
    font-weight: 900;
  }
}
</style>
