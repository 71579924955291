<template>
  <svg
    width="375"
    height="505"
    viewBox="0 0 375 505"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M183 0.0102539L184.211 240.954L235.392 5.52788L186.582 241.459L285.495 21.8396L188.796 242.446L331.119 48.2325L190.756 243.874L370.268 83.5531L192.378 245.678L401.234 126.258L193.589 247.781L422.661 174.48L194.338 250.09L433.614 226.112L194.592 252.505L433.614 278.898L194.338 254.92L422.661 330.53L193.589 257.229L401.234 378.753L192.378 259.332L370.268 421.457L190.756 261.137L331.119 456.778L188.796 262.564L285.495 483.171L186.582 263.551L235.392 499.482L184.211 264.056L183 505L181.788 264.056L130.607 499.482L179.418 263.551L80.5042 483.171L177.204 262.564L34.8809 456.778L175.243 261.137L-4.26894 421.457L173.622 259.332L-35.2342 378.753L172.41 257.229L-56.6616 330.53L171.661 254.92L-67.6147 278.898L171.408 252.505L-67.6147 226.112L171.661 250.09L-56.6616 174.48L172.41 247.781L-35.2342 126.258L173.622 245.678L-4.26894 83.5531L175.243 243.874L34.8809 48.2325L177.204 242.446L80.5042 21.8396L179.418 241.459L130.607 5.52788L181.788 240.954L183 0.0102539Z"
      fill="url(#paint0_radial_3090_2195)"
      fill-opacity="0.12"
    />
    <defs>
      <radialGradient
        id="paint0_radial_3090_2195"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(183 252.505) rotate(90) scale(252.495 251.995)"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </radialGradient>
    </defs>
  </svg>
</template>
