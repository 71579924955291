<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import useStore from '@/store';
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon.vue';
import CountdownTimer from '@/components/CountdownTimer.vue';
import ArrowRightIcon from '@/components/icons/ArrowRightIcon.vue';
import SeasonDays from '@/components/SeasonDays.vue';
import { PageName } from '@/types';
import { useHapticFeedback } from '@/directives/haptic';
import CompressedImage from '@/components/CompressedImage.vue';

const coverSrcSeason = 'season';
const coverSrcLastSeason = 'lastSeason';

const router = useRouter();
const store = useStore();

const actionButtonText = computed(() => {
  if (isEndSeason.value && isCompletedSeasonTask.value) {
    return 'Wait for the season to end';
  }
  if (isCompletedSeasonTask.value) {
    return 'Wait for the next trial';
  } else {
    return 'Begin the trial';
  }
});

const actualTask = ref(store.getSeasonalTaskByDay(0));
const seasonReward = computed(() => store.seasonReward);
const nextTask = ref(store.getSeasonalTaskByDay(1));
const season = ref(store.getSeason());

const sortedSeasonalTasks = computed(() => {
  if (season.value) {
    return store
      .getUniqueSeasonTasks(season.value.id)
      .sort((task1, task2) => task1.start.localeCompare(task2.start));
  }
  return [];
});

const isCompletedSeasonTask = computed(() => {
  return store.completedTasks?.some(
    task => task.task_id === actualTask.value?.id,
  );
});

const isEndSeason = computed(() => {
  if (season.value) {
    const index = sortedSeasonalTasks.value.findIndex(
      task => task.id === actualTask.value?.id,
    );
    return index === sortedSeasonalTasks.value.length - 1;
  }
  return false;
});

const nextTaskAvailableTimestamp = computed(() => {
  if (nextTask.value) {
    return new Date(nextTask.value.start);
  }
  if (season.value) {
    return new Date(season.value.endTime);
  }
  return null;
});
const goBack = () => {
  useHapticFeedback('soft');
  router.push({ name: PageName.World });
};
const updateData = () => {
  setTimeout(() => {
    season.value = store.getSeason();
    nextTask.value = store.getSeasonalTaskByDay(1);
    actualTask.value = store.getSeasonalTaskByDay(0);
    store.fetchSeasonReward();
  }, 1000);
};
const performAction = () => {
  useHapticFeedback('soft');
  router.push({
    name: PageName.TaskSurvey,
    params: { id: actualTask.value?.id, step: 0 },
  });
};
const openSeasonReward = () => {
  router.push({
    name: PageName.SeasonReward,
  });
};

watch(
  store,
  newStore => {
    if (newStore) {
      season.value = store.getSeason();
      actualTask.value = store.getSeasonalTaskByDay(0);
      nextTask.value = store.getSeasonalTaskByDay(1);
    }
  },
  { immediate: true },
);
watch(
  () => store.actualSeason,
  () => {
    store.fetchSeasonReward();
  },
  { immediate: true },
);
watch(
  () => store.user,
  () => {
    store.fetchSeasonReward();
  },
  { immediate: true },
);
</script>

<template>
  <div class="task-view task-view--reward">
    <div class="back-button" @click="goBack">
      <ArrowLeftIcon />
    </div>
    <div class="cover">
      <CompressedImage :src="coverSrcSeason" alt="Quest cover" />
      <div class="cover-blackout"></div>
    </div>
    <div
      :class="[
        'pre-reward-container',
        {
          'pre-reward-container--reward-active':
            seasonReward && !seasonReward?.claimed,
        },
      ]"
    >
      <div class="season-name">
        <div class="season-name--text">
          {{ season?.title }}
        </div>
      </div>
      <h1 class="title">Seasonal Tasks</h1>
      <div class="description">
        Claim your destiny, or fall forgotten.
        <br />
        <span>Only the true samurai rise.</span>
      </div>
    </div>
    <div
      v-if="seasonReward && !seasonReward?.claimed"
      class="season-reward"
      @click="openSeasonReward"
    >
      <span>
        Claim {{ seasonReward?.title }} <br />
        Rewards
      </span>
      <div class="cover-gradient"></div>
      <CompressedImage :src="coverSrcLastSeason" alt="Quest cover" />
      <div class="season-reward--icon">
        <ArrowRightIcon />
      </div>
    </div>
    <SeasonDays
      :next-task="nextTask"
      :actual-task="actualTask"
      :season="season"
    />
    <div class="timerView">
      <CountdownTimer
        v-if="nextTaskAvailableTimestamp"
        class="tasks-value--timer"
        :timestamp="nextTaskAvailableTimestamp"
        v-bind="
          isCompletedSeasonTask ? { textBefore: 'wait' } : { textAfter: 'left' }
        "
        @completed="updateData"
      />
    </div>
    <div class="action-button__wrapper">
      <button
        class="action-button"
        :disabled="
          isCompletedSeasonTask || (isEndSeason && isCompletedSeasonTask)
        "
        @click="performAction"
      >
        {{ actionButtonText }}
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$border-radius: 4vw;
$reward-height: 13vh;
.task-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 46vw;

  .back-button {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10vw;
    height: 10vw;
    background: #141414;
    top: 4vw;
    left: 4vw;
    border-radius: 100%;
    z-index: 1000;
    cursor: pointer;

    svg {
      width: 6vw;
      height: 6vw;
      fill: white;
    }
  }

  .cover {
    position: relative;
    width: 100%;
    height: 50vh;

    img {
      width: 100%;
      height: 100%;
      padding-bottom: 2px;
      object-fit: cover;
      vertical-align: bottom;
    }
  }

  .cover-blackout {
    position: absolute;
    height: 30vh;
    width: 100vw;
    left: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(20, 20, 20, 0) 0%, #0e0e0e 100%);
  }
  .pre-reward-container {
    position: relative;
    margin-top: -30vh;
    width: 92vw;

    &--reward-active {
      margin-top: -35vh;
    }
    .season-name {
      width: 92vw;
      text-align: left;

      &--text {
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        display: inline-block;
        width: auto;
        border-radius: 116.8rem;
        padding: 9rem 14rem;
        background: #e3232c;
        border: 1px solid #ffffff33;
        box-shadow: 0px 0px 20px 0px #e3232c;
      }
    }

    .title {
      position: relative;
      width: 92vw;
      margin-top: 3vh;
      font-size: 8vw;
      line-height: 100%;
      text-align: left;
    }

    .description {
      position: relative;
      width: 92vw;
      margin-top: 4vw;
      font-size: 4vw;
      text-align: left;
      color: #8b8b8b;
    }
  }
  .season-reward {
    position: relative;
    top: 2vh;
    display: flex;
    height: 5vh;
    overflow: hidden;
    align-items: center;
    width: 90vw;
    padding: 4vh 3vw;
    border-radius: 1vh;
    span {
      position: fixed;
      font-weight: 600;
      text-align: left;
      width: 45vw;
      z-index: 1000;
    }
    .cover-gradient {
      position: absolute;
      height: 30vh;
      width: 100vw;
      left: 0;
      bottom: 0;
      background: linear-gradient(
        90deg,
        rgba(244, 83, 55, 1) 0%,
        rgba(244, 83, 55, 1) 40%,
        rgba(244, 83, 55, 0) 100%
      );
      z-index: 999;
    }
    img {
      width: 253rem;
      height: 195rem;
      position: relative;
      transform: translateY(20%) translateX(131rem);
    }
    &--icon {
      position: absolute;
      right: 0;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 8vw;
      height: 8vw;
      margin: 0 2.5vw;
      background: rgba(black, 50%);
      border-radius: 50%;

      svg {
        height: 3.5vw;
        width: auto;
        max-width: 4vw;
        fill: white;
      }
    }
  }

  .tasks-view {
    position: relative;
    width: 90vw;
    height: 15vh;
    background: transparent;
    margin-top: 6vh;
    border: 1px solid #ffffff0a;
    border-radius: 1vh;
    padding-top: 1vh;

    &--description {
      display: flex;
      justify-content: space-between;
      padding-left: 1vh;
      padding-right: 1vh;
      font-size: 14px;
      font-weight: 500;
      line-height: 20.3px;
      letter-spacing: 0.02em;
      text-align: left;
    }

    &--circles {
      margin-top: 1vh;
      display: flex;
      overflow-x: auto;
      gap: 3vw;
    }
    &--circles::-webkit-scrollbar {
      display: none;
    }
    &--day {
      font-size: 11.77px;
      font-weight: 500;
      letter-spacing: 0.02em;
      text-align: center;
      &--done {
        .task-circle {
          background: #242121;
        }
        span {
          color: #71716d;
        }
      }
      &--not-done {
        .task-circle {
          color: #8b8b8b;
        }
        span {
          color: #71716d;
        }
      }
      &--actual-done {
        .task-circle {
          border: 1px solid #e3232c;
          background: #242121;
        }
      }
      &--actual-not-done {
        .task-circle {
          border: 1px solid #e3232c;
          background: #e3232c33;
        }
      }
      &--next {
        .task-circle {
          background: #e3232c33;
          border: 1px solid #ffffff0a;
        }
        span {
          color: #71716d;
        }
      }
      &--future {
        span {
          color: #71716d;
        }
      }
    }
  }

  .task-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.84px solid #252222;
    background: transparent;
    height: 6vh;
    width: 6vh;
    border-radius: 50%;
    flex-shrink: 0;
    margin-bottom: 1vh;
  }

  .timerView {
    position: fixed;
    bottom: 39vw;
    margin-top: 3vh;
    display: flex;
    width: 80vw;
    justify-content: center;
    align-items: center;
    border-radius: 12px 12px 0px 0px;
    border: 1px solid black;
    background: #3d353566;
    padding: 4vw 0;
    font-size: 17px;
    font-weight: 400;
    text-align: center;
  }
}

.action-button {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4vw 0;
  z-index: 1000;
  width: 100%;
  background: #e3232c;
  box-shadow: 0 1vw 5vw rgba(227, 35, 44, 0.5);
  border-radius: 100vw;

  color: white;
  font-size: 4vw;
  font-weight: 700;
  border: 1px solid transparent;

  &--secondary {
    background: white;
    box-shadow: 0 1vw 5vw rgba(255, 255, 255, 0.5);
    color: black;
  }

  &:disabled {
    background: #0e0e0e;
    box-shadow: none;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.5);
    cursor: not-allowed;
  }

  &__wrapper {
    position: fixed;
    display: flex;
    justify-content: center;
    left: 4vw;
    bottom: 27vw;
    width: 92vw;
  }
}
</style>
